import { useMemo } from "react";
import useDevice from "src/hooks/useDevice";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { PillSizeVariant } from "../types";

const getBaseSize = (
	sizeVariant: PillSizeVariant,
): {
	/**
	 * The base size of the pill (size for mobile)
	 */
	baseSize: number;
} => {
	switch (sizeVariant) {
		case "xs":
			return {
				baseSize: 16,
			};
		case "sm":
			return {
				baseSize: 24,
			};
		case "md":
			return {
				baseSize: 32,
			};
		case "lg":
			return {
				baseSize: 40,
			};
		default:
			return enumNever(sizeVariant);
	}
};

const usePillSizing = (sizeVariant: PillSizeVariant) => {
	const { device } = useDevice();
	return useMemo(() => {
		const { baseSize } = getBaseSize(sizeVariant);
		const size = device === "desktop" ? baseSize + 4 : baseSize;
		return { size, baseSize, paddingHorizontal: 0.5 * size, borderRadius: 0.5 * size };
	}, [device, sizeVariant]);
};
export default usePillSizing;
