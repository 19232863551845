import getRandomString from "src/helpers/getRandomString";
import { create } from "zustand";

interface BumpToastStore {
	subscribers: Record<
		string,
		{
			height: number;
			weight: number;
			group: string | undefined;
		}
	>;
	subscribe: (
		height: number,
		options?: {
			weight?: number;
			id?: string;
			group?: string;
		},
	) => {
		id: string;
		unsubscribe: () => void;
	};
}
const useBumpToastStore = create<BumpToastStore>((set) => ({
	height: 0,
	subscribers: {},
	subscribe: (height, { weight = 10_000, id: idProp, group } = {}) => {
		const id = idProp ?? getRandomString();
		set((state) => {
			state.subscribers[id] = {
				height,
				weight,
				group,
			};
			return {
				subscribers: { ...state.subscribers },
			};
		});
		// unsubscribe
		return {
			id,
			unsubscribe: () => {
				set((state) => {
					delete state.subscribers[id];
					return {
						subscribers: { ...state.subscribers },
					};
				});
			},
		};
	},
}));

export default useBumpToastStore;
