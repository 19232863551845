import { useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import usePalette from "src/hooks/usePalette";
import useStyle from "src/hooks/useStyle";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { color } from "src/styles";
import getOpaquePrimaryOpacity from "src/styles/palette/getOpaquePrimaryOpacity";
import { paddingLarge } from "src/styles/spacing";
import BlurView from "../BlurView";
import Text from "../Text/Text";
import { DEFAULT_PILL_SIZE } from "./constants";
import usePillSizing from "./hooks/usePillSizing";
import { PillProps } from "./types";

const Pill = ({
	children,
	style,
	opaque = false,
	Icon,
	ActionIcon,
	sizeVariant = DEFAULT_PILL_SIZE,
	iconWithChildrenSize,
	textProps,
	blur,
	CustomIcon,
	colorVariant = "Default",
	renderCustomView,
	testID,
	pointerEvents,
	color: iconColor,
}: PillProps) => {
	const { theme } = useStyle();
	const { primary, isFallback } = usePalette();

	const { size, paddingHorizontal, borderRadius } = usePillSizing(sizeVariant);
	const viewStyle = useMemo<StyleProp<ViewStyle>>(() => {
		const hasLeftIcon = Boolean(Icon || CustomIcon);
		const hasRightIcon = Boolean(ActionIcon);
		const isIconOnly = Boolean(
			!children && (hasLeftIcon || hasRightIcon) && !(hasLeftIcon && hasRightIcon),
		);
		return [
			{
				backgroundColor: (() => {
					if (blur) return undefined;
					if (!isFallback) {
						return opaque ? getOpaquePrimaryOpacity(primary) : primary;
					}
					switch (colorVariant) {
						case "Default":
							return opaque
								? color.PillBackgroundOpaque[theme]
								: color.PillBackground[theme];
						case "Destructive":
							return opaque
								? color.DestructiveOpaque[theme]
								: color.Destructive[theme];
						case "Alt":
							return opaque
								? color.PillBackgroundAltOpaque[theme]
								: color.PillBackgroundAlt[theme];
						default:
							return enumNever(colorVariant);
					}
				})(),
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "row",
				borderRadius,
			},
			isIconOnly
				? {
						width: size,
						height: size,
						borderRadius: size / 2,
					}
				: CustomIcon
					? {
							paddingRight: paddingLarge,
							height: size,
						}
					: {
							paddingHorizontal,
							height: size,
						},
			style,
		];
	}, [
		ActionIcon,
		CustomIcon,
		Icon,
		blur,
		borderRadius,
		children,
		colorVariant,
		isFallback,
		opaque,
		paddingHorizontal,
		primary,
		size,
		style,
		theme,
	]);

	const Content = useMemo(() => {
		const iconProps = { color: iconColor };
		return (
			<>
				{!!Icon && !children && <Icon {...iconProps} size={(size ?? 0) / 2} />}
				{CustomIcon && !children && CustomIcon}
				{Icon && children && (
					<View
						style={{
							marginRight: 0.15 * size,
						}}
					>
						<Icon {...iconProps} size={iconWithChildrenSize ?? 0.5 * size} />
					</View>
				)}
				{CustomIcon && children && (
					<View
						style={{
							marginRight: 0.15 * size,
						}}
					>
						{CustomIcon}
					</View>
				)}
				{typeof children === "string" ? (
					textProps ? (
						<Text {...textProps}>{children}</Text>
					) : (
						<Text
							xs={sizeVariant === "xs"}
							small={sizeVariant === "sm"}
							basic={sizeVariant === "md"}
							semiLarge={sizeVariant === "lg"}
						>
							{children}
						</Text>
					)
				) : (
					children
				)}
				{!!ActionIcon && !children && <ActionIcon {...iconProps} size={(size ?? 0) / 2} />}
				{ActionIcon && children && (
					<View
						style={{
							marginLeft: 0.15 * size,
							marginRight: -0.25 * size,
						}}
					>
						<ActionIcon {...iconProps} size={0.4 * size} />
					</View>
				)}
			</>
		);
	}, [
		Icon,
		children,
		iconColor,
		size,
		CustomIcon,
		iconWithChildrenSize,
		textProps,
		sizeVariant,
		ActionIcon,
	]);

	return renderCustomView ? (
		renderCustomView({
			testID,
			pointerEvents,
			viewStyle,
			children: Content,
		})
	) : blur ? (
		<BlurView
			testID={testID}
			pointerEvents={pointerEvents}
			style={[
				viewStyle,
				{
					overflow: "hidden",
				},
			]}
		>
			{Content}
		</BlurView>
	) : (
		<View testID={testID} pointerEvents={pointerEvents} style={viewStyle}>
			{Content}
		</View>
	);
};
export default Pill;
