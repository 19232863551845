import { useEffect, useState } from "react";

const useIsFocused = () => {
	const [isFocused, setIsFocused] = useState(() => document.visibilityState === "visible");
	useEffect(() => {
		const listener = () => {
			if (document.visibilityState === "visible") {
				setIsFocused(true);
			} else {
				setIsFocused(false);
			}
		};
		document.addEventListener("visibilitychange", listener);
		return () => {
			document.removeEventListener("visibilitychange", listener);
		};
	}, []);
	return isFocused;
};
export default useIsFocused;
