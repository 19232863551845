import makeClearingVar from "src/helpers/makeClearingVar";
import { Timestamp_S } from "src/shared/types/general";

const revalidateQueryCache = makeClearingVar<Record<
	string,
	{
		lastUpdatedAt: Timestamp_S;
	}
> | null>({});
export default revalidateQueryCache;
