import { ReactNode, useEffect, useMemo, useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import Animated, {
	runOnJS,
	useAnimatedStyle,
	useSharedValue,
	withSpring,
} from "react-native-reanimated";
import { SpringConfig } from "react-native-reanimated/lib/typescript/animation/springUtils";
import getRandomString from "src/helpers/getRandomString";
import useBumpToastStore from "src/hooks/toast-height/useBumpToastStore";
import useIsFocused from "src/hooks/useIsFocused";
import useSyncRef from "src/hooks/useSyncRef";
import { FixedPosition } from "src/styles/position";
import { paddingLarge, paddingSmall, paddingXXL } from "src/styles/spacing";
import { useSafeArea } from "src/swsh-native";

export const BottomPopInMenuRecommendedHorizontalPadding = paddingXXL;
export interface BottomPopInMenuProps {
	children?: ReactNode;
	style?: StyleProp<ViewStyle>;
	/**
	 * @default 0
	 */
	extraBottom?: number;
	/**
	 * @default true
	 */
	show?: boolean;
	/**
	 * Higher weight items go down to the bottom
	 * @default 100
	 */
	weight?: number;
	/**
	 * If defined, groups together this menu with other menus with the same group allowing them to show on the same level
	 */
	group?: string;
}
const springConfig = {
	damping: 20,
	stiffness: 200,
} satisfies SpringConfig;
const BottomPopInMenu = ({
	children,
	style,
	show = true,
	extraBottom = 0,
	weight = 100,
	group,
}: BottomPopInMenuProps) => {
	const safeArea = useSafeArea();
	const curId = useMemo(() => getRandomString(), []);
	const subscribers = useBumpToastStore((state) => state.subscribers);
	const extraHeight = useMemo(() => {
		let extraHeight = 0;
		let didFindSelf = false;
		const groupHeightLookup: Record<string, number> = {};
		for (const [subscriberId, subscriber] of Object.entries(subscribers)) {
			if (subscriberId === curId) {
				didFindSelf = true;
			}
			// Anything with higher priority get counted in
			else if (subscriber.weight > weight || (!didFindSelf && subscriber.weight === weight)) {
				if (subscriber.group != null) {
					if (subscriber.group === group) continue;
					groupHeightLookup[subscriber.group] = Math.max(
						groupHeightLookup[subscriber.group] ?? 0,
						subscriber.height,
					);
				} else {
					extraHeight += subscriber.height;
				}
			}
		}
		for (const groupHeight of Object.values(groupHeightLookup)) {
			extraHeight += groupHeight;
		}
		return extraHeight;
	}, [curId, group, subscribers, weight]);

	const [height, setHeight] = useState<number | null>(null);
	useEffect(() => {
		if (height == null) return;
		if (!show) return;
		const { subscribe } = useBumpToastStore.getState();
		const { unsubscribe } = subscribe(height, { weight, id: curId, group });
		return () => {
			unsubscribe();
		};
	}, [curId, group, height, show, weight]);

	const opacity = useSharedValue(0);
	const y = useSharedValue(100);

	const isFocused = useIsFocused();
	const showRef = useSyncRef(show);
	const [isRendered, setIsRendered] = useState(show);
	useEffect(() => {
		if (!isFocused) return;
		if (show) {
			setIsRendered(true);
			opacity.value = 1;
			y.value = withSpring(
				-(
					Math.max(paddingLarge, safeArea.bottom + paddingSmall) +
					extraBottom +
					extraHeight
				),
				springConfig,
			);
		} else {
			const fixIsRendered = () => {
				if (showRef.current) return;
				setIsRendered(false);
			};
			opacity.value = 0;
			y.value = withSpring(100, springConfig, () => {
				"worklet";
				runOnJS(fixIsRendered)();
			});
		}
	}, [show, extraBottom, extraHeight, opacity, y, safeArea, isFocused, showRef]);

	const animatedStyle = useAnimatedStyle(
		() => ({
			opacity: withSpring(opacity.value, springConfig),
			transform: [
				{
					translateY: y.value,
				},
			],
		}),
		[opacity, y],
	);

	if (!isRendered) return null;
	return (
		<Animated.View
			onLayout={(event) => {
				setHeight(event.nativeEvent.layout.height + paddingSmall);
			}}
			style={[
				{
					position: FixedPosition,
					bottom: 0,
					alignSelf: "center",
				},
				style,
				animatedStyle,
			]}
		>
			{children}
		</Animated.View>
	);
};
export default BottomPopInMenu;
