import { BlurView as ExpoBlurView } from "expo-blur";
import getBackgroundColor from "expo-blur/src/getBackgroundColor";
import { useMemo } from "react";
import useStyle from "src/hooks/useStyle";
import { enumNever } from "src/shared/helpers/generalHelpers";
import { BlurViewProps } from "./types";

const parseIntensity = (intensity: NonNullable<BlurViewProps["intensity"]>): number => {
	if (typeof intensity === "number") {
		return intensity;
	}
	switch (intensity) {
		case "light":
			return 20;
		case "medium":
			return 40;
		case "heavy":
			return 80;
		case "floating-menu":
			return 40;
		default:
			return enumNever(intensity);
	}
};

const BlurView = ({
	children,
	intensity: intensityProp = "heavy",
	tint: tintProp,
	style: styleProp,
	...props
}: BlurViewProps) => {
	const { theme } = useStyle();
	const { tint, intensity } = useMemo(() => {
		const tint = tintProp || (theme === "Dark" ? "dark" : "light");
		const intensity = parseIntensity(intensityProp);
		return { tint, intensity };
	}, [intensityProp, theme, tintProp]);

	return (
		<ExpoBlurView
			tint={tint}
			intensity={intensity}
			style={[
				{
					backgroundColor: getBackgroundColor(intensity, tint),
				},
				styleProp,
			]}
			{...props}
		>
			{children}
		</ExpoBlurView>
	);
};
export default BlurView;
