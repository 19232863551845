import { memo, useMemo } from "react";
import { StyleProp, ViewStyle } from "react-native";
import Touchable from "src/components/Touchable";
import { paddingMedium } from "src/styles/spacing";
import { Pill } from "src/swsh-native";
import { PillProps } from "src/swsh-native/Pill";
import { DEFAULT_PILL_SIZE } from "src/swsh-native/Pill/constants";
import usePillSizing from "src/swsh-native/Pill/hooks/usePillSizing";
import Loading from "../Loading";
import { TouchProps } from "../Touchable/types";

const PillButton = ({
	children,
	containerStyle,
	loading,
	hitSlop,
	testID,
	// Touch props
	onPress,
	href,
	target,
	linkUrlScheme,
	disabled,

	...pillProps
}: {
	containerStyle?: StyleProp<ViewStyle>;
	children?: PillProps["children"];
	loading?: boolean;
	testID?: string;
} & PillProps &
	TouchProps) => {
	const { size, borderRadius } = usePillSizing(pillProps.sizeVariant ?? DEFAULT_PILL_SIZE);
	const resolvedContainerStyle = useMemo<StyleProp<ViewStyle>>(() => {
		return [
			containerStyle,
			{
				borderRadius,
				overflow: "hidden",
			},
		];
	}, [borderRadius, containerStyle]);
	return (
		<Touchable
			testID={testID}
			containerStyle={resolvedContainerStyle}
			onPress={onPress}
			disabled={disabled || loading}
			hitSlop={hitSlop}
			href={href}
			target={target}
			linkUrlScheme={linkUrlScheme}
		>
			<Pill
				{...pillProps}
				CustomIcon={
					loading ? (
						<Loading
							size={size / 2}
							style={
								children
									? {
											marginLeft: paddingMedium,
										}
									: {}
							}
						/>
					) : (
						pillProps?.CustomIcon
					)
				}
				Icon={loading ? undefined : pillProps?.Icon}
			>
				{children}
			</Pill>
		</Touchable>
	);
};
export default memo(PillButton);
