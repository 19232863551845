import { useEffect } from "react";
import { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";

const useAnimatedOpacityStyle = (
	opacity: number,
	{
		duration = 300,
	}: {
		/**
		 * @default 300
		 */
		duration?: number;
	} = {},
) => {
	const opacitySV = useSharedValue(opacity);

	useEffect(() => {
		opacitySV.value = opacity;
	}, [opacitySV, opacity]);

	const animatedStyle = useAnimatedStyle(
		() => ({
			opacity: withTiming(opacitySV.value, {
				duration,
			}),
		}),
		[opacitySV, duration],
	);

	return animatedStyle;
};
export default useAnimatedOpacityStyle;
