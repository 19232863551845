import { makeVar, ReactiveVar } from "@apollo/client";
import { clearingVariables } from "src/api/graphql/variables";

/**
 * Identical to makeVar but automatically registers the variable to be cleared on logout
 */
const makeClearingVar = <T>(value: T): ReactiveVar<T> => {
	const reactiveVar = makeVar(value);
	clearingVariables.push({ defaultValue: value, reactiveVar });
	return reactiveVar;
};
export default makeClearingVar;
